/* tslint:disable:max-file-line-count */
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { delay, map, shareReplay, takeUntil } from 'rxjs/operators';
import { AdminTitle } from '../../../layout/AdminTitle';
import { SiteApi } from '../../../api/auth/SiteApi';
import { JobstProductImporterFormApi } from '../../../api/product/tena/jobst/JobstProductImporterFormApi';
import { JobstService } from './Jobst.service';
import { JobstSiteMapApi } from '../../../api/product/tena/jobst/JobstSiteMapApi';
import { IJobstProduct } from '../../../model/product/tena/jobst/IJobstProduct';
import { ISite } from '../../../model/site/ISite';
import { LiveStageService } from '../../../environment/LiveStage.service';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import CrudListFilter from '../../../crud/list/CrudListFilter';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { ShowMoreAdminCrudList } from '../../../crud/list/ShowMoreAdminCrudList';
import { sortBy } from 'lodash';
import { CelumApi } from '../../../api/celum/CelumApi';

type ProductCrud = BaseCrud<IJobstProduct, ICrudData<IJobstProduct>>;
@Component({
    templateUrl: 'JobstProductImporterForm.component.html',
    providers: [JobstProductImporterFormApi, SiteApi, JobstSiteMapApi]
})
export class JobstProductImporterFormComponent
    extends ShowMoreAdminCrudList<IJobstProduct, ICrudData<IJobstProduct>>
    implements OnInit, OnDestroy {

    sites: Observable<ISite[]>;
    url: string;
    ckeConfig: any;
    tempImageCelumId: string;
    underwearTypes$: any[];
    usageOccasions$: any[];
    productFeatures$: any[];
    tempImageUrlArray: any = [];
    errorMessages: string[] = [];
    pieces: { values: number[] }[] = [];
    benefitTypes: string[] = ['USP', 'HIGHLIGHT', 'USAGE_OCCASION', 'PRODUCT_FACTS',];
    variantKeyMap = {
        'Compression Class': 'compression-class',
        'Style': 'length-type',
        'Top Band': 'top-band',
        'Zipper': 'zipper',
        'Toe Option': 'toe-option',
    };
    attributeKeyMap = {
        'How to care': 'how-to-care',
        'Usage instructions (How/When)': 'usage-instructions-how-to-use-usage-unit'
    };
    @Input() selectedSiteId: string;
    isSyncSuccessful: boolean;
    enableSync: boolean;

    private readonly filter = new CrudListFilter<{ siteId: string }>(this);
    private liveStageSubscription: Subscription;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private httpClient: HttpClient,
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        protected jobstProductImporterFormApi: JobstProductImporterFormApi,
        private liveStageService: LiveStageService,
        private siteApi: SiteApi,
        private jobstSiteMapApi: JobstSiteMapApi,
        private jobstService: JobstService,
        private cdr: ChangeDetectorRef,
        private celumApi: CelumApi
    ) {
        super(elementRef, prettySubmitGuard, 'Product');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Product Importer Form');
        this.liveStageService.addListener(this, 'HmsProductUploader');
        this.selectedSiteId = undefined;
        this.jobstProductImporterFormApi.selectedSiteId = this.selectedSiteId;

        this.liveStageSubscription = this.liveStageService.liveStage.subscribe(() => {
            this.jobstSiteMapApi.getAll().subscribe(jobstSites => {
                // @ts-ignore @todo
                this.sites = this.siteApi
                    .getSitesByBrand('jobst').pipe(
                        // @ts-ignore @todo
                        map(sites => sortBy(sites, 'name')),
                        shareReplay(),);
                this.sites.subscribe(sites => {
                    const filteredSites = sites.filter(site =>
                        jobstSites.some(jobstSite => jobstSite.siteUrl === site.domainUrl)
                    );
                    this.sites = of(filteredSites);
                });
            });
        });

        this.ckeConfig = {
            allowedContent: false,
            forcePasteAsPlainText: true,
            toolbarGroups: [
                {
                    name: 'productImporterForm',
                    groups: ['basicstyles', 'list', 'undo', 'clipboard', 'links']
                }
            ]
        };

        this.jobstService.segmentChanged.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => {
            this.initProducts();
        });
    }

    initProducts() {
        this.filter.observable.subscribe(filter => {
            let siteId = JSON.parse(window.sessionStorage.getItem('jobstSelectedCountry'));
            siteId = siteId.objectId;

            if (siteId) {
                setTimeout(() => {
                    this.clearProductsList();
                }, 0);

                this.jobstProductImporterFormApi
                    .getProductsBySite(siteId)
                    .subscribe(products => {
                        this.init(sortBy(products, 'longName'));
                    });
                this.selectedSiteId = siteId;
                this.jobstProductImporterFormApi.selectedSiteId = this.selectedSiteId;
            }
        });
    }

    clearProductsList() {
        this.items = [];
    }

    addBenefit(crud) {
        if (!crud.data.item.productBenefits) {
            crud.data.item.productBenefits = [];
        }

        crud.data.item.productBenefits.unshift({
            type: '',
            name: '',
            headline: '',
            description: '',
            benefitThumbnailId: '',
            thumbnailUrls: {},
            urls: {},
            thumbnailPreview: '',
            thumbnailUrl: '',
            tooltipText: ''
        });
    }

    removeBenefit(crud, index) {
        crud.data.item.productBenefits.splice(index, 1);
    }

    addAttribute(crud) {
        if (!crud.data.item.productAttributes) {
            crud.data.item.productAttributes = [];
        }

        crud.data.item.productAttributes.unshift({
            name: '',
            value: '',
            englishValue: '',
            multiValue: [],
            englishMultiValue: []
        });
    }

    removeAttribute(crud, index) {
        crud.data.item.productAttributes.splice(index, 1);
    }

    addMultiValue(attribute) {
        if (!attribute.multiValue) {
            attribute.multiValue = [];
        }

        attribute.multiValue.unshift('');
    }

    removeMultiValue(attribute, index) {
        attribute.multiValue.splice(index, 1);
    }

    addEnglishMultiValue(attribute) {
        if (!attribute.englishMultiValue) {
            attribute.englishMultiValue = [];
        }

        attribute.englishMultiValue.unshift('');
    }

    removeEnglishMultiValue(attribute, index) {
        attribute.englishMultiValue.splice(index, 1);
    }

    addColorDecor(crud) {
        if (!crud.data.item.colorDecor) {
            crud.data.item.colorDecor = [];
        }

        crud.data.item.colorDecor.unshift({
            name: '',
            hexCode: '#000000'
        });
    }

    removeColorDecor(crud, index) {
        crud.data.item.colorDecor.splice(index, 1);
    }

    trackByIndex(index: number, obj: any): any {
        return index;
    }

    addMediaGallery(crud: ProductCrud) {
        if (!crud.data.item.mediaGalleries) {
            crud.data.item.mediaGalleries = [];
        }

        crud.data.item.mediaGalleries.unshift({
            celumId: '',
            contentType: '',
            mimeType: '',
            altText: '',
            mediaShortDescription: '',
            mediaLongDescription: '',
            thumbnailUrls: {},
            urls: {},
            imagePreview: ''
        });

        this.errorMessages.push('');
    }

    removeMediaGallery(crud: ProductCrud, index: number) {
        crud.data.item.mediaGalleries.splice(index, 1);
        this.errorMessages.splice(index, 1);
    }

    fetchCelumData(mediaGalleries: any, index: number) {
        const celumId = mediaGalleries.celumId;
        if (!celumId) {
            this.errorMessages[index] = 'Celum ID cannot be empty!';

            return;
        }

        const apiUrl = `${this.celumApi.getBaseUrl()}${celumId}`;

        this.httpClient.get(apiUrl).subscribe(
            (response: any) => {
                this.errorMessages[index] = '';

                if (!mediaGalleries.urls) {
                    mediaGalleries.urls = {};
                }
                if (!mediaGalleries.thumbnailUrls) {
                    mediaGalleries.thumbnailUrls = {};
                }

                const imageData = response.find((item: any) => item.Variant === 'AzurePNG4K');
                const videoThumbnailData = response.find((item: any) => item.Variant === 'Video_Thumbnail');

                if (imageData) {
                    mediaGalleries.imagePreview = imageData.CDNUrl;
                } else if (videoThumbnailData) {
                    mediaGalleries.imagePreview = videoThumbnailData.CDNUrl;
                } else {
                    this.errorMessages[index] = 'No valid image or video thumbnail found for this Celum ID.';
                }

                if (!this.errorMessages[index]) {
                    if (videoThumbnailData) {
                        response.forEach((item: any) => {
                            mediaGalleries.thumbnailUrls[item.Variant] = item.CDNUrl;
                        });
                    } else {
                        response.forEach((item: any) => {
                            mediaGalleries.urls[item.Variant] = item.CDNUrl;
                        });
                    }
                }

                this.cdr.detectChanges();
            },
            (error) => {
                this.errorMessages[index] = 'Error fetching Celum data: ' + error.message;
            }
        );
    }

    fetchThumbnailData(benefit: any, benefitIndex: number) {
        const thumbnailId = benefit.benefitThumbnailId;
        if (!thumbnailId) {
            this.errorMessages[benefitIndex] = 'Thumbnail ID cannot be empty!';

            return;
        }

        const apiUrl = `${this.celumApi.getBaseUrl()}${thumbnailId}`;

        this.httpClient.get(apiUrl).subscribe(
            (response: any) => {
                this.errorMessages[benefitIndex] = '';

                if (!benefit.thumbnailUrls) {
                    benefit.thumbnailUrls = {};
                }

                const imageData = response.find((item: any) => item.Variant === 'AzurePNG4K');
                const videoThumbnailData = response.find((item: any) => item.Variant === 'Video_Thumbnail');

                if (imageData) {
                    benefit.thumbnailUrl = imageData.CDNUrl;
                    benefit.thumbnailPreview = imageData.CDNUrl;
                } else if (videoThumbnailData) {
                    benefit.thumbnailPreview = videoThumbnailData.CDNUrl;
                } else {
                    this.errorMessages[benefitIndex] = 'No valid image or video thumbnail found for this Thumbnail ID.';
                }

                if (!this.errorMessages[benefitIndex]) {
                    if (videoThumbnailData) {
                        response.forEach((item: any) => {
                            benefit.thumbnailUrls[item.Variant] = item.CDNUrl;
                        });
                    } else {
                        response.forEach((item: any) => {
                            benefit.urls[item.Variant] = item.CDNUrl;
                        });
                    }
                }

                this.cdr.detectChanges();
            },
            (error) => {
                this.errorMessages[benefitIndex] = 'Error fetching thumbnail data: ' + error.message;
            }
        );
    }

    addVariant(crud) {
        if (!crud.data.item.variants) {
            crud.data.item.variants = [];
        }
        crud.data.item.variants.unshift({
            name: '',
            key: '',
            value: ''
        });
    }

    removeVariant(crud, index) {
        crud.data.item.variants.splice(index, 1);
    }

    addPieces(crud) {
        if (!crud.data.item.pieces) {
            crud.data.item.pieces = [];
        }
        crud.data.item.pieces.unshift({ value: null, baseNumberAndEanPair: '' });
    }

    removePieces(crud, index) {
        crud.data.item.pieces.splice(index, 1);
    }

    removePieceValue(piece, index) {
        piece.values.splice(index, 1);
    }

    onAttributeNameChange(attribute) {
        attribute.key = this.attributeKeyMap[attribute.name] || '';
    }

    onVariantNameChange(variant) {
        variant.key = this.variantKeyMap[variant.name] || '';
    }

    updateFilter() {
        var filter = this.filter;
        if (!filter.setFilter({ siteId: this.selectedSiteId })) {
            setTimeout(() => {
                this.selectedSiteId = filter.getFilter().siteId;
                this.initProducts();
            }, 0);
        } else {
            this.initProducts();
        }
    }

    ngOnDestroy(): void {
        this.liveStageService.removeListener(this);
        this.liveStageSubscription.unsubscribe();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    protected createCrud() {
        return new GuidCrud(this.jobstProductImporterFormApi);
    }
}