import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Route } from '@angular/router';
import { INavbarRoute } from '../../layout/navbar/INavbarRoute';

import { Tool } from '../../model/auth/login/Tool';
import { JobstCatalogComponent } from '../../product/tena/jobst/JobstCatalog.component';
import { JobstCatalogIdentificationMappingComponent } from '../../product/tena/mapping/JobstCatalogIdentificationMapping.component';
import { JobstSiteMapComponent } from '../../product/tena/jobst/JobstSiteMap.component';
import { JobstProductImporterFormComponent } from '../../product/tena/jobst/JobstProductImporterForm.component';

export const JobstChildRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Catalogs',
        path: 'catalogs',
        component: JobstCatalogComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592398/4.2.1+Pim+Tena+Catalogs',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Catalog Segment ⬌ Identification',
        path: 'mapping/catalog-segment-identification',
        component: JobstCatalogIdentificationMappingComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592226/4.4+Pim+Mappings',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Site Maps',
        path: 'site-maps',
        component: JobstSiteMapComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592738/4.2.5+Pim+Tena+Site+Maps',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Product Uploader Form',
        path: 'product-importer-form',
        component: JobstProductImporterFormComponent,
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/171540819/7.4+Femcare+Product+Importer+How+To+s',
        // requiredAccess: {
        //     tool: [Tool.ProductImporter]
        // } 
    }
];
